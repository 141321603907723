import { Rating } from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { Fragment, useLayoutEffect, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import { getRequestDetail, sendQualificationAndRating } from '../../api/ServiceRequestedDetails';
import { cacheConfig } from '../../cacheConfig';
import CenterLoading from '../../components/CenterLoading/CenterLoading';
import DeskNotification from '../../components/DeskNotification/DeskNotification';
import FormModal from '../../components/FormModal/FormModal';
import TextField from '../../components/TextField/TextField';
import { ROLE_TYPE } from '../../constants/constants';
import { HideGlobalLoading, ShowGlobalLoading, UpdateAppSubHeaderTitle } from '../../redux/actions/UiActions';
import { BodyText, ButtonsMenuContainer, MediumHeightDivider, Row, SmallHeightDivider, StyledButtonOutlined } from '../../theme/Styles';
import { FormRatingSchema, MenuOptions } from './ServiceRequestedDetailsConstants';
import { Container } from './styles/ServiceRequestedDetailsStyles';
import ActionsRequired from './subViews/actionsRequired/ActionsRequired';
import ComplaintsAndClaims from './subViews/complaintsAndClaims/ComplaintsAndClaims';
import Details from './subViews/details/Details';
import Messages from './subViews/messages/Messages';
import Payment from './subViews/payments/Payments';

function ServiceRequestedDetails() {
    const history = useHistory();
    const location = useLocation();

    // Obtener los query params
    const queryParams = new URLSearchParams(location.search);
    const docIdentification = queryParams.get('doc_identification');
    const userRolCompany = queryParams.get('user_rol_company');
    const statusID = queryParams.get('status_id');
    const companyID = queryParams.get('company_id');
    const companyName = queryParams.get('company_name');

    const { requestID } = useParams();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const [activeMenu, setActiveMenu] = useState(0);
    const [ratingModalIsOpen, setRatingModalIsOpen] = useState(false);
    const [rating, setRating] = useState(3);
    const { rolBase, citizenID } = useSelector((state) => state.authReducer);

    const [selectedActionRequired, setSelectedActionRequired] = useState();
    const actionRequiredRef = useRef(null);

    const [availableMenus, setAvailableMenus] = useState({
        details: true,
        claims: true,
        payment: false,
        actionRequired: false,
        messages: false
    });

    const formik = useFormik({
        initialValues: {
            comment: ''
        },
        validationSchema: FormRatingSchema,
        onSubmit: (values) => {
            handleSendRating(values);
        }
    });

    const { data: serviceRequestedDetail, isLoading } = useQuery(
        ['serviceRequestedDetail', requestID],
        async () => {
            try {
                dispatch(ShowGlobalLoading('Cargando'));
                const response = await getRequestDetail(
                    requestID,
                    rolBase == ROLE_TYPE.COMPANY || userRolCompany == ROLE_TYPE.REPRESENTATIVE ? docIdentification : citizenID
                );
                if (response?.request === undefined) {
                    // history.push("/public");
                    throw Error;
                }
                dispatch(HideGlobalLoading());
                return response;
            } catch (error) {
                // history.push("/public");
                dispatch(HideGlobalLoading());
            }
        },
        {
            staleTime: cacheConfig.staleTimeForRequestedServiceDetail
        }
    );

    const mutation = useMutation(sendQualificationAndRating);

    const handleChangeMenu = (menuID) => {
        setActiveMenu(menuID);
        window.location.hash = '';
        setSelectedActionRequired(null);
    };

    const handleRatingModalVisibility = () => {
        setRatingModalIsOpen(!ratingModalIsOpen);
    };

    const handleSendRating = (formData) => {
        dispatch(ShowGlobalLoading('Cargando'));
        mutation.mutate(
            {
                request_id: requestID,
                rating: rating,
                comment: formData.comment
            },
            {
                onSettled: () => {
                    dispatch(HideGlobalLoading());
                },
                onSuccess: () => {
                    enqueueSnackbar('Calificación enviada con exito', { variant: 'success' });
                    queryClient.invalidateQueries(['serviceComments', serviceRequestedDetail.request.service.id + '']);
                    queryClient.invalidateQueries(['serviceRequestedDetail', requestID]);
                    handleRatingModalVisibility();
                },
                onError: () => {
                    enqueueSnackbar('Ha ocurrido un error', { variant: 'error' });
                }
            }
        );
    };

    const handleCompleteActionRequired = (actionRequired) => {
        setSelectedActionRequired(actionRequired);

        if (docIdentification != citizenID) {
            setActiveMenu(MenuOptions.details);
            return;
        }
        switch (actionRequired?.action_id) {
            case 1:
                setActiveMenu(MenuOptions.actionRequired);
                break;
            case 3:
                setActiveMenu(MenuOptions.messages);
                break;
            case 5:
                setActiveMenu(MenuOptions.payment);
                break;
            case 7:
                setActiveMenu(MenuOptions.payment);
                break;
            default:
                setActiveMenu(MenuOptions.details);
                break;
        }
        actionRequiredRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };

    const finishActionRequired = () => {
        setActiveMenu(MenuOptions.details);
        setSelectedActionRequired(null);
    };

    const setAvailableSubViews = (requestActions = []) => {
        let localAvailableMenus = {
            details: true,
            claims: true,
            payment: false,
            actionRequired: false,
            messages: false
        };
        //show payment menu
        if (
            (serviceRequestedDetail.request.service.external_pay == 1 && serviceRequestedDetail.request?.payment?.payment_amount > 0) ||
            (serviceRequestedDetail.request.service.sirit_code != null && serviceRequestedDetail.request?.payment?.payment_amount > 0) ||
            serviceRequestedDetail.priceRequest?.length > 0
        ) {
            localAvailableMenus = { ...localAvailableMenus, payment: true };
        }
        //show messages menu
        if (serviceRequestedDetail.request.comments.length > 0 || requestActions.some((item) => item?.action_id === 3)) {
            localAvailableMenus = { ...localAvailableMenus, messages: true };
        }
        //show document or action required menu
        /*   if (requestActions.some((item) => item?.action_id === 1)) {
              localAvailableMenus = { ...localAvailableMenus, actionRequired: true }
          } */
        setAvailableMenus(localAvailableMenus);
    };

    useLayoutEffect(() => {
        //UPDATE APP HEADER SUBTITLE, SET THE SERVICE NAME AND TOGGLE TO SPECIFIC MENU
        if (serviceRequestedDetail !== undefined) {
            dispatch(UpdateAppSubHeaderTitle(serviceRequestedDetail.request.service.name));
            setAvailableSubViews(serviceRequestedDetail.requiredActions);
            if (statusID != 10 && statusID != 8 && statusID != 7)
                serviceRequestedDetail?.requiredActions?.map((item) => handleCompleteActionRequired(item));
        }
    }, [serviceRequestedDetail]);

    if (isLoading) return <CenterLoading />;
    return (
        <Container>
            <Row>
                <Container style={{ width: '100%' }}>
                    <ButtonsMenuContainer>
                        <ButtonGroup size="large">
                            <StyledButtonOutlined
                                active={activeMenu === MenuOptions.details}
                                onClick={() => handleChangeMenu(MenuOptions.details)}
                            >
                                Detalles
                            </StyledButtonOutlined>
                            {statusID != 10 ? (
                                <StyledButtonOutlined
                                    active={activeMenu === MenuOptions.claims}
                                    onClick={() => handleChangeMenu(MenuOptions.claims)}
                                >
                                    Quejas y Reclamaciones
                                </StyledButtonOutlined>
                            ) : null}
                            {availableMenus.payment && docIdentification == citizenID ? (
                                <StyledButtonOutlined
                                    active={activeMenu === MenuOptions.payment}
                                    onClick={() => handleChangeMenu(MenuOptions.payment)}
                                >
                                    Pagos
                                </StyledButtonOutlined>
                            ) : null}
                            {availableMenus.messages && (
                                <StyledButtonOutlined
                                    active={activeMenu === MenuOptions.messages}
                                    onClick={() => handleChangeMenu(MenuOptions.messages)}
                                >
                                    Mensajes
                                </StyledButtonOutlined>
                            )}
                            {/* availableMenus.actionRequired &&
                            <StyledButtonOutlined
                                active={activeMenu == MenuOptions.actionRequired}
                                onClick={() => handleChangeMenu(MenuOptions.actionRequired)}
                            >
                                Accion Requerida
                            </StyledButtonOutlined> */}
                        </ButtonGroup>
                    </ButtonsMenuContainer>
                    <MediumHeightDivider />
                    {rolBase !== ROLE_TYPE.COMPANY &&
                        docIdentification === citizenID &&
                        serviceRequestedDetail?.requiredActions?.map((item) => (
                            <Fragment>
                                <SmallHeightDivider />
                                <Fragment>
                                    <DeskNotification
                                        variant={'warning'}
                                        isSelected={selectedActionRequired?.id === item?.id && serviceRequestedDetail?.requiredActions}
                                        disableCloseButton={true}
                                        showButton={serviceRequestedDetail?.request?.status?.base_status_id == 1}
                                        buttonTitle="Completar"
                                        onButtonClick={() => handleCompleteActionRequired(item)}
                                        message={item.message}
                                    />
                                    <SmallHeightDivider />
                                </Fragment>
                                <SmallHeightDivider />
                            </Fragment>
                        ))}
                    {rolBase !== ROLE_TYPE.COMPANY && serviceRequestedDetail?.request?.solution && (
                        <Fragment>
                            <DeskNotification
                                disableAnimation
                                variant={serviceRequestedDetail.request.status.color}
                                disableCloseButton={true}
                                message={serviceRequestedDetail.request.solution}
                                showButton={serviceRequestedDetail.request.rating?.length <= 0}
                                buttonTitle="CALIFICAR"
                                onButtonClick={() => handleRatingModalVisibility()}
                            />
                            {serviceRequestedDetail.request.rating?.length <= 0 && (
                                <div style={{ width: '22%' }}>
                                    {/* <StyledButtonOutlined onClick={handleRatingModalVisibility} variant='outlined'>CALIFICAR</StyledButtonOutlined> */}
                                    <FormModal
                                        title="Calificación de servicio"
                                        open={ratingModalIsOpen}
                                        fullWidth
                                        onClose={handleRatingModalVisibility}
                                    >
                                        <SmallHeightDivider />
                                        <SmallHeightDivider />
                                        <strong>
                                            <BodyText style={{ marginLeft: '5px' }}>Calificación</BodyText>
                                        </strong>
                                        <Rating
                                            onChange={(e, newValue) => setRating(newValue)}
                                            value={rating}
                                            precision={0.5}
                                            size="large"
                                        />

                                        <TextField
                                            placeholder="Comentario"
                                            id="comment"
                                            value={formik.values.comment}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.comment && Boolean(formik.errors.comment)}
                                            helperText={formik.touched.comment && formik.errors.comment}
                                            multiline
                                            minRows={6}
                                        />
                                        <SmallHeightDivider />
                                        <SmallHeightDivider />
                                        <div style={{ width: '40%' }}>
                                            <StyledButtonOutlined
                                                onClick={formik.handleSubmit}
                                                variant="outlined"
                                            >
                                                Enviar Calificación
                                            </StyledButtonOutlined>
                                        </div>
                                        <SmallHeightDivider />
                                        <SmallHeightDivider />
                                    </FormModal>
                                </div>
                            )}
                            <SmallHeightDivider />
                        </Fragment>
                    )}
                    <div ref={actionRequiredRef}>
                        {activeMenu === MenuOptions.details ? (
                            <Details />
                        ) : activeMenu === MenuOptions.claims ? (
                            <ComplaintsAndClaims
                                companyID={companyID}
                                companyName={companyName}
                            />
                        ) : activeMenu === MenuOptions.payment ? (
                            <Payment />
                        ) : activeMenu === MenuOptions.messages ? (
                            <Messages />
                        ) : (
                            activeMenu === MenuOptions.actionRequired && (
                                <ActionsRequired
                                    finishActionRequired={finishActionRequired}
                                    data={selectedActionRequired}
                                />
                            )
                        )}
                    </div>
                </Container>
            </Row>
        </Container>
    );
}

export default ServiceRequestedDetails;
