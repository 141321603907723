import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from '@mui/material';
import { useFormik } from 'formik';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import Fade from 'react-reveal/Fade';
import { useHistory } from 'react-router';
import Swal from 'sweetalert2';
import { changeMember, searchUserByCitizenId } from '../../../../api/myCompany';
import {
    getAllRequestedServicesByCompanyIDWithFilters,
    getRequestedServicesCitizenIDWithAllCompanyMembers
} from '../../../../api/RequestedServiceList';
import CenterLoading from '../../../../components/CenterLoading/CenterLoading';
import RequestCard from '../../../../components/RequestCard/RequestCard';
import Select from '../../../../components/Select/Select';
import TextField from '../../../../components/TextField/TextField';
import COLORS from '../../../../theme/Colors';
import { InputFilter, MediumHeightDivider, Row, RowMedia, SmallHeightDivider, StyledPagination } from '../../../../theme/Styles';
import { requestFilterTypes } from '../../MyDeskConstants';
import { ListContainer, SectionTitle } from '../../styles/MyDeskStyles';
import { FormCompanySendInviteSchema } from '../Company/CompanyConstants';
import DialogChangeApplicant from '../Company/components/DialogChangeApplicant';

function MyRequests({ rolBase }) {
    // const matchesWidth = useMediaQuery("(min-width:768px)");
    const history = useHistory();
    const initialDataApplicant = {
        value: 0,
        label: 'Todos los solicitantes'
    };
    const finishData = {
        value: 0,
        label: 'Sin registros'
    };
    // const dispatch = useDispatch();
    const { companyID, citizenID } = useSelector((state) => state.authReducer);

    const queryClient = useQueryClient();
    const userData = queryClient.getQueryData(['userData']);
    const [companySelected, setCompanySelected] = useState(null);
    const [requestCode, setRequestCode] = useState();
    const [requestForApplicant, setRequestForApplicant] = useState([]);
    const [requestForApplicantId, setRequestForApplicantId] = useState(0);

    const [requestType, setRequestType] = useState(1);
    const [requestName, setRequestName] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [stringFilter, setStringFilter] = useState('');

    const titleRef = useRef(null);

    const [claimModalVisible, setClaimModalVisible] = useState(false);
    const [searchUser, setSearchUser] = useState(null);

    const mutationSearchUserById = useMutation(searchUserByCitizenId);
    const mutationChangeMember = useMutation(changeMember);

    const { data: requestsList, isLoading: requestListIsLoading } = useQuery(
        ['requestsList', requestType, stringFilter, currentPage, requestForApplicantId],
        () => getAllRequestedServicesByCompanyIDWithFilters(companyID, currentPage, requestType, stringFilter, requestForApplicantId, citizenID),
        {
            staleTime: 60000
        }
    );

    const { data: requestListByCompanyMembers, isLoading: requestListByCompanyMembersIsLoading } = useQuery(
        ['requestListByCompanyMembers'],
        () => getRequestedServicesCitizenIDWithAllCompanyMembers(citizenID),
        {
            staleTime: 60000
        }
    );

    useEffect(() => {
        if (requestForApplicantId != 0) {
            setCurrentPage(1);
        }
    }, [requestForApplicantId]);

    useEffect(() => {
        if (requestsList?.data?.length > 0 && requestListByCompanyMembers?.payload?.companies?.length > 0) {
            setRequestForApplicant(() => [initialDataApplicant, ...requestListByCompanyMembers?.payload?.members]);
        }
    }, [requestsList]);

    const searchUserById = (formData) => {
        mutationSearchUserById.mutate(formData, {
            onSuccess: (data) => {
                if (data.success) {
                    setSearchUser(data.payload);
                    // refresh cache of searchUserId
                    queryClient.invalidateQueries('searchUserId');
                } else {
                    Swal.fire({
                        title: 'Información',
                        text: data.msg,
                        icon: 'info'
                    });
                }
            }
        });
    };

    const handleTypeChangeApplicant = (value) => {
        setRequestForApplicantId(value.target.value);
    };

    const onChangeOwnerFormik = useFormik({
        initialValues: {
            citizen_id: ''
        },
        validationSchema: FormCompanySendInviteSchema,
        onSubmit: (values) => {
            searchUserById(values.citizen_id);
        }
    });

    const handleTypeChange = (value) => {
        handlePageChange(1);
        setRequestType(value.target.value);
        setRequestName('');
        titleRef.current.scrollIntoView();
    };

    const handlePageChange = (value) => {
        setCurrentPage(value);
        titleRef.current.scrollIntoView();
    };

    const handleStringFilter = () => {
        handlePageChange(1);
        setStringFilter(requestName);
    };

    const handleRemoveFilters = () => {
        setStringFilter('');
        setRequestName('');
    };

    const handleClaimModalVisibility = () => {
        setSearchUser(null);
        onChangeOwnerFormik.resetForm();
        setClaimModalVisible(!claimModalVisible);
    };

    const deleteFoundUser = () => {
        setSearchUser(null);
        onChangeOwnerFormik.resetForm();
    };

    const handlerChangeOwner = (citizenID) => {
        changeOwner({ request_code: requestCode, new_citizen_id: citizenID, company_id: companySelected });
    };

    const changeOwner = (formData) => {
        mutationChangeMember.mutate(formData, {
            onSuccess: (data) => {
                if (data.success) {
                    Swal.fire({
                        title: 'Información',
                        text: data.msg,
                        icon: 'success'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            setSearchUser(undefined);
                            setRequestCode(undefined);
                            handleClaimModalVisibility();
                        }
                    });
                    // refresh cache of inviteUser
                    queryClient.invalidateQueries('requestsList');
                } else {
                    Swal.fire({
                        title: 'Información',
                        text: data.msg,
                        icon: 'info'
                    });
                }
            }
        });
    };

    if (requestListIsLoading) return <CenterLoading />;

    return (
        <Fade right>
            <MediumHeightDivider />
            <RowMedia style={{ justifyContent: 'space-between' }}>
                <Row style={{ alignItems: 'center' }}>
                    <SectionTitle
                        ref={titleRef}
                        style={{ width: 250 }}
                    >
                        {`Solicitudes ${requestFilterTypes.find((type) => type.value === Number(requestType))?.label?.toLowerCase()}`}
                    </SectionTitle>
                </Row>
                <RowMedia style={{ justifyContent: 'flex-end' }}>
                    <Select
                        title={'Filtro por solicitante'}
                        id="filterSolicitante"
                        data={requestForApplicant.length == 0 ? [finishData] : requestForApplicant}
                        value={requestForApplicantId}
                        onChange={handleTypeChangeApplicant}
                        disableEmptyValue
                        marginRight={6}
                    />
                    <Select
                        title={'por status'}
                        id="filterType"
                        data={requestFilterTypes}
                        value={requestType}
                        onChange={handleTypeChange}
                        disableEmptyValue
                    />
                </RowMedia>
            </RowMedia>
            <div style={{ height: '5px' }} />

            <InputFilter style={{ position: 'relative' }}>
                <TextField
                    placeholder="Nombre o número de solicitud"
                    type="text"
                    id="requestID"
                    value={requestName}
                    onChange={(e) => setRequestName(e.target.value)}
                />
                {stringFilter.length > 0 ? (
                    <div style={{ width: '40px', position: 'absolute', zIndex: 100, top: 20, right: 10 }}>
                        <IconButton
                            onClick={() => handleRemoveFilters()}
                            aria-label="delete"
                        >
                            <CloseIcon
                                titleAccess="Eliminar filtro"
                                color="error"
                                sx={{ fontSize: '1.2em' }}
                            />
                        </IconButton>
                    </div>
                ) : (
                    <div style={{ width: '40px', position: 'absolute', zIndex: 100, top: 20, right: 10 }}>
                        <IconButton
                            onClick={handleStringFilter}
                            aria-label="delete"
                        >
                            <SearchIcon
                                titleAccess="buscar"
                                color="action"
                                sx={{ fontSize: '1.2em' }}
                            />
                        </IconButton>
                    </div>
                )}
            </InputFilter>

            <ListContainer>
                {requestsList?.data?.map((request, index) => (
                    <Fragment key={request.id}>
                        {index > 0 && <SmallHeightDivider />}
                        <RequestCard
                            title={request.service.name}
                            percent={request.progress + '%'}
                            date={request.created_at}
                            rolBase={rolBase}
                            company_user_rol={request.service.user_rol_company}
                            onClaimModalVisibility={(value) => handleClaimModalVisibility(value)}
                            requestCode={request.code}
                            citizenID={citizenID}
                            status={request.status.name}
                            actionRequired={request.request_actions}
                            docIdentification={request.doc_identification}
                            handleCompanySelected={setCompanySelected}
                            code={request.code}
                            setRequestCode={setRequestCode}
                            companyID={request.company_id}
                            companyName={request.company_name}
                            onClick={() => {
                                const queryParams = new URLSearchParams({
                                    doc_identification: request.doc_identification,
                                    user_rol_company: request.service.user_rol_company,
                                    status_id: request.status.id,
                                    company_id: request.company_id,
                                    company_name: request.company_name
                                }).toString();

                                return history.push(`/app/serviceRequestedDetails/${request.id}?${queryParams}`);
                            }}
                            statusID={request.status.id}
                            solution={request.solution}
                            solutionColor={request.status.color}
                            projectName={request.nmtramite}
                            userName={`${request.user_name} ${request.user_first_last_name} ${request.user_last_name} - ${request.doc_identification}`}
                        />
                    </Fragment>
                ))}

                <MediumHeightDivider />
                {requestsList?.data?.length > 0 ? (
                    <StyledPagination
                        count={requestsList?.last_page}
                        page={currentPage}
                        onChange={(event, page) => {
                            handlePageChange(page);
                        }}
                        variant="outlined"
                        shape="rounded"
                        sx={{ color: COLORS.primary }}
                    />
                ) : (
                    <SectionTitle>No se encontraron registros</SectionTitle>
                )}

                <MediumHeightDivider />
            </ListContainer>
            <DialogChangeApplicant
                claimModalVisible={claimModalVisible}
                handleClaimModalVisibility={handleClaimModalVisibility}
                companySelected={companySelected}
                searchUser={searchUser}
                deleteFoundUser={deleteFoundUser}
                onClick={handlerChangeOwner}
                title="Cambiar propietario"
                sendButtonLabel={'ENVIAR'}
            />
        </Fade>
    );
}

export default MyRequests;
